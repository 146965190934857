import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import './App.css';

const App = () => {
  const [parametriData, setParametriData] = useState([]);
  const [error, setError] = useState(null);
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [operatorMessages, setOperatorMessages] = useState([]);
  const [masuriPreventive, setMasuriPreventive] = useState([]);

  useEffect(() => {
    document.title = "Vehicle Monitoring";

    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.vdf.h22.ro:8443/api/parametri');
        if (Array.isArray(response.data)) {
          const oneHourAgo = new Date(Date.now() - 3600000);
          const filteredData = response.data.filter(entry => new Date(entry.timestamp) >= oneHourAgo).map(entry => ({
            timestamp: new Date(entry.timestamp).toLocaleTimeString(),
            viteza: entry.viteza,
            nivel_ulei: entry.nivel_ulei,
            stare_placute: entry.stare_placute,
            presiune_pneuri: entry.presiune_pneuri,
            nivel_baterie: entry.nivel_baterie,
          }));
          setParametriData(filteredData);
          
          const latestData = filteredData[filteredData.length - 1];
          if (latestData) {
            const messages = [];
            if (latestData.viteza > 120) messages.push({ text: 'Viteza este prea mare! Reduceți viteza.', color: 'red' });
            else messages.push({ text: 'Viteza este normală.', color: 'green' });

            if (latestData.nivel_ulei < 20) messages.push({ text: 'Nivelul de ulei este scăzut! Completați uleiul.', color: 'red' });
            else messages.push({ text: 'Nivelul de ulei este normal.', color: 'green' });

            if (latestData.stare_placute < 30) messages.push({ text: 'Plăcuțele de frână sunt uzate! Schimbați-le cât mai curând.', color: 'red' });
            else messages.push({ text: 'Starea plăcuțelor de frână este normală.', color: 'green' });

            if (latestData.presiune_pneuri < 30) messages.push({ text: 'Presiunea pneurilor este scăzută! Verificați pneurile.', color: 'red' });
            else messages.push({ text: 'Presiunea pneurilor este normală.', color: 'green' });

            if (latestData.nivel_baterie < 12) messages.push({ text: 'Nivelul bateriei este scăzut! Încarcați bateria.', color: 'red' });
            else messages.push({ text: 'Nivelul bateriei este normal.', color: 'green' });

            setOperatorMessages(messages);
          }

        } else {
          throw new Error("Response data is not an array");
        }
      } catch (err) {
        console.error("Error fetching data: ", err.message || err);
        setError("Failed to fetch data from server");
      }
    };

    if (isAuthenticated) {
      fetchData();
      const intervalId = setInterval(fetchData, 10000);
      return () => clearInterval(intervalId);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const fetchMasuriPreventive = async () => {
      try {
        const response = await axios.get('https://api.vdf.h22.ro:8443/api/masuri-preventive');
        if (Array.isArray(response.data)) setMasuriPreventive(response.data);
        else throw new Error("Response data is not an array");
      } catch (err) {
        console.error("Error fetching preventive measures: ", err.message || err);
      }
    };

    if (isAuthenticated) fetchMasuriPreventive();
  }, [isAuthenticated]);

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === 'login') setIsAuthenticated(true);
    else alert('Incorrect password. Please try again.');
  };

  if (!isAuthenticated) {
    return (
      <div className="login-container dark-theme">
        <h2>Enter Password</h2>
        <form onSubmit={handlePasswordSubmit}>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
            required
          />
          <button type="submit">Submit</button>
        </form>
      </div>
    );
  }

  if (error) return <div className="error-message dark-theme">Error: {error}</div>;

  return (
    <div className="dark-theme">
      <div className="logo-container main-container">
        <img src="/vodafone-logo.png" alt="Vodafone Logo" className="logo" />
      </div><br/><br/><br/>
	<h2>Vehicle Parameters Dashboard - Demo Page</h2>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
        <div style={{ width: 'calc(50% - 10px)', height: 400 }}>
          <h3>Viteza</h3>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={parametriData}>
              <CartesianGrid strokeDasharray="3 3" stroke="#444" />
              <XAxis dataKey="timestamp" stroke="#fff" />
              <YAxis dataKey="viteza" stroke="#fff" />
              <Tooltip contentStyle={{ backgroundColor: '#333', borderColor: '#555', color: '#fff' }} />
              <Legend wrapperStyle={{ color: '#fff' }} />
              <Line type="monotone" dataKey="viteza" stroke="#8884d8" activeDot={{ r: 8 }} />
            </LineChart>
          </ResponsiveContainer>
        </div>

        <div style={{ width: 'calc(50% - 10px)', height: 400 }}>
          <h3>Nivel Ulei</h3>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={parametriData}>
              <CartesianGrid strokeDasharray="3 3" stroke="#444" />
              <XAxis dataKey="timestamp" stroke="#fff" />
              <YAxis dataKey="nivel_ulei" stroke="#fff" />
              <Tooltip contentStyle={{ backgroundColor: '#333', borderColor: '#555', color: '#fff' }} />
              <Legend wrapperStyle={{ color: '#fff' }} />
              <Line type="monotone" dataKey="nivel_ulei" stroke="#82ca9d" />
            </LineChart>
          </ResponsiveContainer>
        </div>

        <div style={{ width: 'calc(50% - 10px)', height: 400 }}>
          <h3>Stare Plăcuțe de Frână</h3>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={parametriData}>
              <CartesianGrid strokeDasharray="3 3" stroke="#444" />
              <XAxis dataKey="timestamp" stroke="#fff" />
              <YAxis dataKey="stare_placute" stroke="#fff" />
              <Tooltip contentStyle={{ backgroundColor: '#333', borderColor: '#555', color: '#fff' }} />
              <Legend wrapperStyle={{ color: '#fff' }} />
              <Line type="monotone" dataKey="stare_placute" stroke="#ffc658" />
            </LineChart>
          </ResponsiveContainer>
        </div>

        <div style={{ width: 'calc(50% - 10px)', height: 400 }}>
          <h3>Presiune Pneuri</h3>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={parametriData}>
              <CartesianGrid strokeDasharray="3 3" stroke="#444" />
              <XAxis dataKey="timestamp" stroke="#fff" />
              <YAxis dataKey="presiune_pneuri" stroke="#fff" />
              <Tooltip contentStyle={{ backgroundColor: '#333', borderColor: '#555', color: '#fff' }} />
              <Legend wrapperStyle={{ color: '#fff' }} />
              <Line type="monotone" dataKey="presiune_pneuri" stroke="#ff7300" />
            </LineChart>
          </ResponsiveContainer>
        </div>

        <div style={{ width: 'calc(50% - 10px)', height: 400 }}>
          <h3>Nivel Baterie</h3>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={parametriData}>
              <CartesianGrid strokeDasharray="3 3" stroke="#444" />
              <XAxis dataKey="timestamp" stroke="#fff" />
              <YAxis dataKey="nivel_baterie" stroke="#fff" />
		<Tooltip contentStyle={{ backgroundColor: '#333', borderColor: '#555', color: '#fff' }} />		
	  	<Legend wrapperStyle={{ color: '#fff' }} />
              <Line type="monotone" dataKey="nivel_baterie" stroke="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
        </div>

        <div style={{ width: 'calc(50% - 10px)', height: 400 }}>
          <h3>Mesaje pentru Operator</h3>
          <div
            style={{
              padding: '20px',
              backgroundColor: 'black',
              color: '#fff',
              borderRadius: '10px',
              textAlign: 'center',
              fontSize: '16px',
              maxHeight: '100%',
              overflowY: 'auto'
            }}
          >
            {operatorMessages.map((msg, idx) => (
              <p key={idx} style={{ color: msg.color, margin: '10px 0' }}>{msg.text}</p>
            ))}
          </div>
	  <div style={{ width: 'calc(100% - 0px)', height: 400 }}>
  		<h3>Măsuri Preventive</h3>
	        <div
            style={{
              padding: '20px',
              backgroundColor: 'black',
              color: '#fff',
              borderRadius: '10px',
              textAlign: 'center',
              fontSize: '16px',
              maxHeight: '100%',
              overflowY: 'auto'
            }}
	  >
  		{masuriPreventive.length > 0 ? (
      		<ul>	
		{masuriPreventive.map((masura, index) => (
        	<p key={index} style={{ color: 'white',  margin: '10px 0' }}>{masura.descriere}</p>
      		))}
		</ul>
  		) : (
    		<p>Nu există măsuri preventive în acest moment.</p>
  		)}
	  </div>
	  </div>
        </div>
      </div>
      <footer className="footer dark-theme">
        <p>&copy; 2024 _VOIS - SLS IITC Team</p>
      </footer>
    </div>
  );
}

export default App;
